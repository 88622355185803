import type { ApiFilter, Filter, SliderValues } from '@/types';
import { computed } from 'vue';
import { formatPrice } from '@/helpers/utils';
import { ATTRIBUTE_SCHEMA_MAPPING } from '@/libraries/api/adapterMapping';
import { useDataStore } from '@/stores/data';

export const budgetFilter: Filter<SliderValues> = {
    type: 'budget',

    value: [15000, 15000],
    defaultValue: [15000, 15000],
    valueFormat: 'slider',
    options: computed(() => []),
    noPreference: false,

    isDefault: function (): Boolean {
        return (
            this.value[0] == this.defaultValue[0] &&
            this.value[1] == this.defaultValue[1]
        );
    },

    toFormatted: function (): string {
        const budgetRangeMax = useDataStore().budgetRange.max;
        return (
            formatPrice(this.value[0]) +
            '-' +
            formatPrice(this.value[1], false, budgetRangeMax)
        );
    },
    toUrlQueryFormatted: function (): string {
        if (
            this.value[0] === this.defaultValue[0] &&
            this.value[1] === this.defaultValue[1]
        )
            return '';
        return this.value[0] + '-' + this.value[1];
    },
    toFilterFromUrlQuery: function (value): any {
        const range = value.split('-');
        return [range[0], range[1]];
    },
    hasValidValue: function (value): any {
        const range = value.split('-');
        const min = parseInt(range[0]);
        const max = parseInt(range[1]);
        const budgetRange = useDataStore().budgetRange;
        return (
            !isNaN(min) &&
            !isNaN(max) &&
            max > min &&
            min >= budgetRange.min &&
            max <= budgetRange.max
        );
    },
    onValueChanged: function (): void {},
    toApiFilter: function (): ApiFilter[] {
        const budgetRangeMax = useDataStore().budgetRange.max;
        return [
            {
                attribute: ATTRIBUTE_SCHEMA_MAPPING.get(this.type) ?? this.type,
                operator: 'between',
                value:
                    this.value[0] +
                    '-' +
                    (this.value[1] === budgetRangeMax
                        ? this.value[1] + '+'
                        : this.value[1]),
            },
        ];
    },
    toApiSort: function (): string[] {
        return [];
    },
};
