<script setup lang="ts"></script>

<template>
    <div v-for="i in 4" :key="i">
        <details class="faq is-active">
            <summary class="faq__title">
                {{ $t('faq.question_' + i) }}
            </summary>
            <div class="faq__content">
                {{ $t('faq.answer_' + i) }}
            </div>
        </details>
    </div>
</template>
