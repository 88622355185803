<script setup lang="ts">
import Blogs from '../components/Blogs.vue';
import Faqs from '../components/Faqs.vue';
import Steps from '../components/Steps.vue';
import SearchButtons from '@/components/SearchButtons.vue';
import { useI18n } from 'vue-i18n';
import { useMeta } from 'vue-meta';
import SearchButtonsToggle from '@/components/SearchButtonsToggle.vue';
import SearchByFeature from '@/components/SearchByFeature.vue';
import { inject, ref, watch } from 'vue';
import SearchByBrand from '@/components/SearchByBrand.vue';
import { AppConfigInjectionKey } from '../libraries/config';

const { t } = useI18n();

useMeta({
    title: t('meta.home__title'),
    description: t('meta.home__description'),
});

const searchType = ref('features');

function setSearchType(value: string) {
    searchType.value = value;
}

const config = inject(AppConfigInjectionKey);

const brandingState = config?.forced_settings?.branding || 'default';
</script>

<template>
    <section class="hero--homeview">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-10 offset-lg-1">
                    <h1 v-html="$t('home.find_car')"></h1>
                </div>
            </div>
        </div>
    </section>
    <section class="section--homeview">
        <div class="container">
            <search-buttons-toggle
                @searchTypeChanged="setSearchType"
            ></search-buttons-toggle>
        </div>
        <search-by-feature
            :isHomeView="true"
            v-if="searchType === 'features'"
        ></search-by-feature>
        <search-by-brand
            :isHomeView="true"
            v-if="searchType === 'brand'"
        ></search-by-brand>
    </section>
    <section class="section--homeview">
        <div class="container">
            <h2 class="section__heading">{{ $t('home.steps_title') }}</h2>

            <Steps />

            <div
                class="section__content"
                v-html="$t('home.steps_subtext')"
            ></div>
        </div>
    </section>
    <section class="section--homeview">
        <div class="container">
            <h2 class="section__heading">{{ $t('home.blogs_title') }}</h2>

            <Blogs></Blogs>

            <div class="section__buttons">
                <!-- <a class="button--outline" href="#">{{
                    $t('home.button_blogs_more')
                }}</a> -->
            </div>
        </div>
    </section>
    <section
        class="section--homeview"
        v-if="
            brandingState !== 'produpress' && brandingState !== 'produpress_fr'
        "
    >
        <div class="container">
            <h2 class="section__heading">{{ $t('home.faqs_title') }}</h2>
            <Faqs />
        </div>
    </section>
    <section class="section--homeview">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                    <SearchButtons />
                </div>
            </div>
        </div>
    </section>
</template>
