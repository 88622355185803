<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import Step from '../components/Step.vue';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

const { t } = useI18n();

const steps = [
    {
        nr: '1',
        icon: 'as-icon-quiz',
        title: t('home.step1_title'),
        text: t('home.step1_text'),
    },
    {
        nr: '2',
        icon: 'as-icon-compare',
        title: t('home.step2_title'),
        text: t('home.step2_text'),
    },
    {
        nr: '3',
        icon: 'as-icon-select-car',
        title: t('home.step3_title'),
        text: t('home.step3_text'),
    },
    {
        nr: '4',
        icon: 'as-icon-phone',
        title: t('home.step4_title'),
        text: t('home.step4_text'),
    },
];
</script>

<template>
    <div>
        <div class="thumbnail__container hidden-xs hidden-sm">
            <Step
                v-for="(step, index) in steps"
                :key="step.nr"
                :nr="step.nr"
                :icon="step.icon"
                :title="step.title"
                :text="step.text"
            />
        </div>
        <carousel class="hidden-md hidden-lg hidden-xl" :items-to-show="1">
            <slide v-for="slide in steps" :key="slide.nr">
                <Step
                    :nr="slide.nr"
                    :icon="slide.icon"
                    :title="slide.title"
                    :text="slide.text"
                />
            </slide>

            <template #addons>
                <pagination />
            </template>
        </carousel>
    </div>
</template>
