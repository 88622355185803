<script setup lang="ts">
import { inject, ref, onMounted } from 'vue';
import globe from '@/assets/img/icons/globe.svg';
import { AppConfigInjectionKey } from '../libraries/config';

interface LanguageOption {
    label: string;
    abbreviation: string;
    value: string;
    url: string;
}

const config = inject(AppConfigInjectionKey);

const brandingState = config?.forced_settings?.branding || 'default';

const options = ref<LanguageOption[]>(config?.language_switch || []);

const selectedOption = ref<LanguageOption | null>(null);

onMounted(() => {
    if (brandingState === 'produpress_fr') {
        selectedOption.value =
            options.value.find((option) => option.value === 'be-fr') ?? null;
    } else if (brandingState === 'produpress') {
        selectedOption.value =
            options.value.find((option) => option.value === 'be-nl') ?? null;
    }
});

const handleLanguageSwitch = (option: LanguageOption) => {
    window.location.href = option.url;
};
</script>
<template>
    <div class="language_switcher">
        <v-select
            :options="options"
            label="label"
            :searchable="false"
            v-model="selectedOption"
            @option:selected="handleLanguageSwitch"
        >
            <template #selected-option="{ abbreviation }">
                <img :src="globe" alt="globe" class="icon" />
                <span>{{ abbreviation }}</span>
            </template>
        </v-select>
    </div>
</template>
