<script setup lang="ts">
import { AppConfigInjectionKey } from '../libraries/config';
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { brandingConfig } from '@/libraries/brandingConfig';
const { t } = useI18n();

const config = inject(AppConfigInjectionKey);
const brandingState = config?.forced_settings?.branding || 'default';
const brandingConfigState = brandingConfig[brandingState];

const linkTarget =
    brandingState === 'produpress' || brandingState === 'produpress_fr'
        ? '_blank'
        : '_self';
</script>
<template>
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-4">
                    <div class="footer__column">
                        <ul>
                            <li>
                                <a
                                    :href="brandingConfigState?.parent_link"
                                    :target="linkTarget"
                                    >{{ $t('footer.brand_name') }}</a
                                >
                            </li>
                            <li>
                                <span>{{ $t('footer.copyright') }}</span>
                            </li>
                            <li>
                                <a
                                    :href="
                                        brandingConfigState?.parent_link_about
                                    "
                                    :target="linkTarget"
                                    >{{ $t('footer.about') }}</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="footer__column">
                        <ul>
                            <li>
                                <a
                                    :href="
                                        brandingConfigState?.parent_link_privacy
                                    "
                                    :target="linkTarget"
                                    >{{ $t('footer.privacy_policy') }}</a
                                >
                            </li>
                            <li>
                                <a
                                    :href="
                                        brandingConfigState?.parent_link_terms
                                    "
                                    :target="linkTarget"
                                    >{{ $t('footer.terms_an_conditions') }}</a
                                >
                            </li>
                            <li>
                                <a
                                    :href="
                                        brandingConfigState?.parent_link_cookies
                                    "
                                    :target="linkTarget"
                                    >{{ $t('footer.cookies') }}</a
                                >
                            </li>
                            <li>
                                <a
                                    :href="
                                        brandingConfigState?.parent_link_intellectual
                                    "
                                    :target="linkTarget"
                                    >{{ $t('footer.intellectual_rights') }}</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="footer__column">
                        <ul>
                            <li>
                                <a
                                    :href="
                                        brandingConfigState?.parent_link_contact
                                    "
                                    :target="linkTarget"
                                    >{{ $t('footer.contact') }}</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<!--<template>-->
<!--    &lt;!&ndash;Todo: @nienke add actual content and make it translatable&ndash;&gt;-->
<!--    <footer class="footer">-->
<!--        <div class="container">-->
<!--            <div class="row hidden-xs hidden-sm">-->
<!--                <div class="col-12">-->
<!--                    <a class="logo" href="/">-->
<!--                        <img-->
<!--                            :src="logo"-->
<!--                            title="autoselectr"-->
<!--                            alt="autoselectr"-->
<!--                        />-->
<!--                    </a>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="row">-->
<!--                <div class="col-12 col-md-4">-->
<!--                    <div class="footer__column">-->
<!--                        <h4 class="hidden-md hidden-lg hidden-xl">-->
<!--                            <a class="logo" href="/">-->
<!--                                <img-->
<!--                                    :src="logo"-->
<!--                                    title="autoselectr"-->
<!--                                    alt="autoselectr"-->
<!--                                />-->
<!--                            </a>-->
<!--                        </h4>-->
<!--                        <ul>-->
<!--                            <li><a href="/">Home</a></li>-->
<!--                            <li><a href="#">Car finder</a></li>-->
<!--                            <li><a href="#">Blog</a></li>-->
<!--                            <li>-->
<!--                                <a href="/privacy-policy"-->
<!--                                    >Privacy restrictions</a-->
<!--                                >-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="col-12 col-md-4 col-lg-3">-->
<!--                    <div class="footer__column">-->
<!--                        &lt;!&ndash;Todo: toggle ul on mobile (below 768px) by clicking on h4&ndash;&gt;-->
<!--                        <h4>Useful links</h4>-->
<!--                        <ul>-->
<!--                            <li><a href="#">Something else</a></li>-->
<!--                            <li><a href="#">Athlos.com</a></li>-->
<!--                            <li><a href="#">Observador.pt</a></li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div-->
<!--                    class="col-12 col-md-2 hidden-md hidden-sm hidden-xs"-->
<!--                ></div>-->
<!--                <div class="col-12 col-md-4 col-lg-3">-->
<!--                    &lt;!&ndash;Todo: toggle ul on mobile (below 768px) by clicking on h4&ndash;&gt;-->
<!--                    <h4>Contact info</h4>-->
<!--                    <ul>-->
<!--                        &lt;!&ndash;Todo: @nienke add icons&ndash;&gt;-->
<!--                        <li>-->
<!--                            <strong>Tel:</strong>-->
<!--                            <a href="tel:456679675">+456 679 675</a>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <strong>Mail:</strong>-->
<!--                            <a href="mailto:autoselector@gmail.com"-->
<!--                                >autoselector@gmail.com</a-->
<!--                            >-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                    <ul class="socials">-->
<!--                        &lt;!&ndash;Todo: @nienke replace with icons&ndash;&gt;-->
<!--                        <li><a href="">FB</a></li>-->
<!--                        <li><a href="">TW</a></li>-->
<!--                        <li><a href="">IG</a></li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="row">-->
<!--                <div class="col-12">-->
<!--                    <div class="footer__bottom">-->
<!--                        &copy; {{ currentYear }} autoselector.com-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </footer>-->
<!--</template>-->
<script lang="ts">
export default {
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    },
};
</script>
