import type { ApiFilter, Filter } from '@/types';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { ATTRIBUTE_SCHEMA_MAPPING } from '@/libraries/api/adapterMapping';
import { useDataStore } from '@/stores/data';
import { AppConfigInjectionKey } from '@/libraries/config';

export const carSizeFilter: Filter<
    Array<{
        title: string;
        value: string;
    }>
> = {
    type: 'car-size',
    value: [],
    defaultValue: [],
    valueFormat: 'multiple',
    options: computed(() => []),
    noPreference: false,

    isDefault: function (): Boolean {
        return this.value == this.defaultValue;
    },

    toFormatted: function (child: string): string {
        const { t } = useI18n();
        return t('car_size.' + child.replace('-', '_'));
    },
    toUrlQueryFormatted: function (): string {
        const result: string[] = [];
        this.value.forEach((v) => {
            result.push(v.value);
        });
        return result.join(',');
    },
    toFilterFromUrlQuery: function (value): any {
        return {
            title: this.toFormatted(value),
            value: value,
        };
    },
    hasValidValue: function (value): any {
        return useDataStore().carSizes.includes(value);
    },
    onValueChanged: function (): void {},

    toApiFilter: function (): ApiFilter[] {
        const isProdupress = computed(() => {
            const config = inject(AppConfigInjectionKey);
            return (
                config?.forced_settings.branding === 'produpress' ||
                config?.forced_settings.branding === 'produpress_fr'
            );
        });

        const VALUE_SCHEMA_MAPPING = new Map<string, string>([
            ['seating-capacity:small', isProdupress.value ? '2,3,4' : '2'],
            ['seating-capacity:compact', isProdupress.value ? '4,5' : '5'],
            ['seating-capacity:midsize', '5'],
            ['seating-capacity:large', isProdupress.value ? '5,6,7,8' : '7'],
            ['luggage-size:small', '200'],
            ['luggage-size:compact', '400'], // (normal value is 200 but uses <= 400 as the condition)
            ['luggage-size:midsize', '400'],
            ['luggage-size:large', '400'],
        ]);

        const apiFilters: ApiFilter[] = [];

        for (const carSize of this.value) {
            apiFilters.push({
                attribute:
                    ATTRIBUTE_SCHEMA_MAPPING.get('seating-capacity') ??
                    'seating-capacity',
                operator: '=',
                value:
                    VALUE_SCHEMA_MAPPING.get(
                        ('seating-capacity:' + carSize.value) as string
                    ) ?? carSize.value,
            });

            if (!isProdupress.value) {
                apiFilters.push({
                    attribute:
                        ATTRIBUTE_SCHEMA_MAPPING.get('luggage-size') ??
                        'luggage-size',
                    operator: carSize.value == 'compact' ? '<=' : '>=',
                    value:
                        VALUE_SCHEMA_MAPPING.get(
                            ('luggage-size:' + carSize.value) as string
                        ) ?? carSize.value,
                });
            }
        }

        return apiFilters;
    },
    toApiSort: function (): string[] {
        return [];
    },
};
