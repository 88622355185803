import type { AppConfig } from '@/libraries/config';
import type { GtmSupport } from '@gtm-support/vue-gtm';
import type { App } from 'vue';
import type { FilterDataLayerData, Filters } from '@/types';
import type {Router} from "vue-router";

let appRouter: Router;
let gtm: GtmSupport | undefined;
export default async function initAnalytics(
    config: AppConfig,
    app: App<Element>,
    router: Router
) {
    if (config.ga4?.enabled) {
        import('@gtm-support/vue-gtm').then((VueGtm) => {
            app.use(
                VueGtm.createGtm({
                    id: config?.ga4?.id,
                    defer: false,
                    compatibility: false,
                    loadScript: true,
                    vueRouter: router,
                    trackOnNextTick: false,
                })
            );

            gtm = VueGtm.useGtm();
        });
    }

    appRouter = router;
    return gtm;
}

export function trackCompareCarsEvent(
    comparisonCount: number,
    id: string
): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'compare_cars',
        car_id: id.replace('checkbox-car-display-', ''),
        comparison_count: comparisonCount,
    });
}

export function trackViewCarDetailsEvent(id: string): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'view_car_details',
        car_id: id,
    });
}

export function trackLeadFormSubmission(id: string | undefined) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'request_test_ride',
        car_id: id,
    });
}

export function trackFilterEvent(filters: Filters) {
    if (!gtm?.enabled()) return;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(
        getFilterData(
            filters,
            Object.keys(appRouter.currentRoute.value.query).length === 0
                ? 'search'
                : 'car_filter'
        )
    );
}

function getFilterData(
    filters: Filters,
    eventName: string
): FilterDataLayerData {
    return {
        event: eventName,
        car_brand: filters['car-brand'].value,
        car_model: filters['car-model'].value,
        car_version: filters['car-version'].value,
        car_type: filters['car-type'].value?.map((el) => {
            return el.value;
        }),
        car_size: filters['car-size'].value?.map((el) => {
            return el.value;
        }),
        fuel_type: filters['fuel-type'].value?.map((el) => {
            return el.value;
        }),
        budget: filters['budget'].value,
        important_feature: filters['important-feature'].value?.map((el) => {
            return el.value;
        }),
    };
}
