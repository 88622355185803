<script setup lang="ts">
const emit = defineEmits(['indicatorSelected']);

const props = defineProps({
    stepCount: {
        type: Number,
        default: 0,
    },
    currentIndex: {
        type: Number,
        default: 0,
    },
    staticPosition: {
        type: Boolean,
        default: false,
    },
    isHomeView: {
        type: Boolean,
        default: false,
    },
});

function onClick(index: Number) {
    emit('indicatorSelected', index);
}
</script>

<template>
    <div class="progress_indicator--desktop">
        <div
            v-for="index in stepCount"
            :key="index"
            class="progress_indicator__wrap"
        >
            <div
                class="progress_indicator__step"
                :class="[
                    {
                        'progress_indicator__step--completed':
                            index < currentIndex,
                    },
                    {
                        'progress_indicator__step--current':
                            index == currentIndex,
                    },
                ]"
                @click="onClick(index)"
            >
                <span>{{ index }}</span>
            </div>

            <div
                v-if="stepCount != index"
                class="progress_indicator__connector"
                :class="{
                    'progress_indicator__connector--completed':
                        index < currentIndex,
                }"
            ></div>
        </div>
    </div>
    <div v-if="isHomeView" class="progress_indicator--mobile--homeview">
        <div class="progress_indicator__slider">
            <div
                class="progress_indicator__progress"
                :style="{ width: (currentIndex / stepCount) * 100 + '%' }"
            ></div>
        </div>
    </div>
    <div
        v-else
        :class="[
            { 'progress_indicator--mobile': !props.staticPosition },
            { 'progress_indicator--mobile-static': props.staticPosition },
        ]"
    >
        <p>Step {{ currentIndex }} of {{ stepCount }}</p>
        <div class="progress_indicator__slider">
            <div
                class="progress_indicator__progress"
                :style="{ width: (currentIndex / stepCount) * 100 + '%' }"
            ></div>
        </div>
    </div>
</template>
