import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

export default function initSentry(app: any, router: any, dsn: string) {
    Sentry.init({
        app,
        dsn,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: [
                    'api.autoselectr.k.elnino-acceptance.com',
                    'api.autoselectr.k.elnino-production.com',
                    'api.autoselectr.com',
                    /^\//,
                ],
            }),
        ],
        tracesSampleRate: 1.0,
    });
}
