<script setup lang="ts">
import { computed, inject } from 'vue';
import { RouterView } from 'vue-router';
import { AppConfigInjectionKey } from './libraries/config';
import TheHeader from './components/TheHeader.vue';
import TheFooter from './components/TheFooter.vue';
import { inIframe } from './iframe/utils';

const showHeader = computed(() => {
    const config = inject(AppConfigInjectionKey);
    if (config?.forced_settings?.hide_header === true) {
        // forced by config
        return false;
    } else if (inIframe()) {
        // Do not show when in Iframe
        return false;
    }
    return true;
});

const showFooter = computed(() => {
    const config = inject(AppConfigInjectionKey);
    if (config?.forced_settings?.hide_footer === true) {
        // forced by config
        return false;
    } else if (inIframe()) {
        // Do not show when in Iframe
        return false;
    }
    return true;
});
</script>

<template>
    <div class="page_wrapper">
        <metainfo>
            <template v-slot:title="{ content }">{{
                content ? `${content}` : `Autoselectr`
            }}</template>
        </metainfo>
        <TheHeader v-if="showHeader" />

        <main>
            <RouterView />
        </main>

        <TheFooter v-if="showFooter" />
    </div>
</template>
