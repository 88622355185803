<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useFiltersStore } from '@/stores/filters';
import { useRouter, useRoute } from 'vue-router';
import ProgressIndicator from '../../components/ProgressIndicator.vue';
import CarTypeFilter from '../../components/FeatureSearchFilters/CarTypeFilter.vue';
import CarSizeFilter from '../../components/FeatureSearchFilters/CarSizeFilter.vue';
import BudgetFilter from '../../components/FeatureSearchFilters/BudgetFilter.vue';
import FuelTypeFilter from '../../components/FeatureSearchFilters/FuelTypeFilter.vue';
import ImportantFeatureFilter from '../../components/FeatureSearchFilters/ImportantFeatureFilter.vue';
import Toggle from '../../components/Toggle.vue';
import { useDataStore } from '@/stores/data';
import {usePaginationStore} from "@/stores/pagination";
import {useSortsStore} from "@/stores/sorts";

const store = useFiltersStore();
const dataStore = useDataStore();
const router = useRouter();
const route = useRoute();
const paginationStore = usePaginationStore();
const sortsStore = useSortsStore();

interface SearchFilter {
    component: any;
    name: string;
    value: any;
}

const searchFilters: SearchFilter[] = [
    {
        component: CarTypeFilter,
        name: 'car-type',
        value: '',
    },
    {
        component: CarSizeFilter,
        name: 'car-size',
        value: '',
    },
    {
        component: BudgetFilter,
        name: 'budget',
        value: [
            dataStore.budgetRange.min,
            dataStore.budgetRange.defaultMax,
        ] as Array<number | string>,
    },
    {
        component: FuelTypeFilter,
        name: 'fuel-type',
        value: '',
    },
    {
        component: ImportantFeatureFilter,
        name: 'important-feature',
        value: '',
    },
];

let currentIndex = ref(1);
if (route.query.index != null) {
    currentIndex.value = Number(route.query.index);
}

const currentSearchFilter = computed(() => {
    return searchFilters[currentIndex.value - 1] ?? {};
});

function onFilterSelected(event: any) {
    searchFilters[currentIndex.value - 1].value = event;
}

function onSubmit() {
    updateIndex(currentIndex.value + 1);

    if (currentIndex.value == searchFilters.length + 1) {
        paginationStore.resetPagination();
        sortsStore.resetSorts();
        router.push({ name: 'suggested-cars' });
    }
}

function updateIndex(newValue: any) {
    if (newValue < 1) currentIndex.value = 1;
    else currentIndex.value = newValue;
    router.push({
        query: {
            index: currentIndex.value,
        },
    });
}

watch(route, () => {
    const index = Number(route.query.index ?? 1);
    currentIndex.value = index;
});

function onNextFilter() {
    updateIndex(currentIndex.value + 1);
}

function onSeeResults() {
    onSubmit();
}

function switchPage(toggleValue: boolean) {
    router.push({ name: 'observador-search-by-brand' });
}

function progressBarWidth() {
    const p = currentIndex.value * (100 / (searchFilters.length + 1));
    return {width: p + '%'}
}

</script>

<template>
    <section class="full_page_container pt-0">
        <div class="container">
            <form class="form" @submit.prevent="onSubmit">
                <h2>{{ $t('search_by_brand.title') }}</h2>
                <Toggle
                    :options="[
                        $t('buttons.search_by_features'),
                        $t('buttons.search_by_brand'),
                    ]"
                    :initial-value="0"
                    v-on:valueChanged="switchPage"
                ></Toggle>
                <component v-if="currentSearchFilter.component"
                           :is="currentSearchFilter.component"
                           :value="currentSearchFilter.value"
                           :index="currentIndex"
                           v-on:filterSelected="onFilterSelected"
                           v-on:nextFilter="onNextFilter"
                           v-on:seeResults="onSeeResults">
                    <template #progress-observador>
                        <div class="progress-bar-observador">
                            <div class="progress-bar-fill" :style="progressBarWidth()"></div>
                        </div>
                    </template>
                </component>

                <div class="form__buttons--multi form__buttons--relative">
                    <div class="container search-by-features--observador">
                        <a
                            v-if="currentIndex > 1"
                            class="button--left-arrow button--outline"
                            @click="updateIndex(currentIndex - 1)"
                        >{{ $t('buttons.previous') }}</a
                        >
                        <span v-else class="button--left-arrow button--outline is-disabled">
                            {{ $t('buttons.previous') }}
                        </span>
                        <button
                            type="submit"
                            class="button--right-arrow form__buttons__button_right"
                        >
                            {{currentIndex == searchFilters.length
                            ? $t('buttons.see_results')
                            : $t('buttons.next')}}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>
