<script setup lang="ts">
import Toggle from '@/components/Toggle.vue';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
const { t } = useI18n();

const searchType = ref('features');
const emit = defineEmits(['searchTypeChanged']);

function onSearchOptionClicked(value: string) {
    switch (value) {
        case t('buttons.search_by_features'):
            searchType.value = 'features';
            break;
        case t('buttons.search_by_brand'):
            searchType.value = 'brand';
            break;
        default:
            searchType.value = 'features';
    }
    emit('searchTypeChanged', searchType.value);
}
</script>

<template>
    <toggle
        :options="[
            $t('buttons.search_by_features'),
            $t('buttons.search_by_brand'),
        ]"
        v-on:valueChanged="onSearchOptionClicked"
        :initialValue="0"
    ></toggle>
</template>
