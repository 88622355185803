<script setup lang="ts">
import { inject, computed } from 'vue';
import { brandingConfig } from '@/libraries/brandingConfig';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import { AppConfigInjectionKey } from '../libraries/config';

const config = inject(AppConfigInjectionKey);
const brandingState = config?.forced_settings?.branding || 'default';
const showLanguageSwitcher =
    config?.forced_settings?.show_language_switcher || false;

const logoUrl = computed(() => {
    return (
        brandingConfig[brandingState as string]?.logo ||
        brandingConfig['default'].logo
    );
});
</script>

<template>
    <header class="header">
        <div class="container">
            <div class="header__content">
                <RouterLink :to="{ name: 'home' }" class="logo">
                    <img :src="logoUrl" title="autoselectr" alt="autoselectr" />
                </RouterLink>
                <ul class="header__nav">
                    <!--Todo: make 'is-active' functional when menu gets expanded-->
                    <li class="is-active">
                        <RouterLink
                            :to="{ name: 'home' }"
                            class="logo"
                            id="header-home"
                        >
                            <span class="hidden-xs">Home</span>
                            <i class="as-icon-home visible-xs"></i>
                        </RouterLink>
                    </li>
                </ul>
                <LanguageSwitcher v-if="showLanguageSwitcher" />
            </div>
        </div>
    </header>
</template>
