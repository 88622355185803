import {defineStore} from "pinia";
import {ref} from "vue";

export const usePaginationStore = defineStore('pagination', () => {
    const currentPageIndex = ref<number>(0);
    const currentPageOffset = ref<number>(0);

    function resetPagination()
    {
        currentPageIndex.value = 0;
        currentPageOffset.value = 0;
    }

    return {
        currentPageIndex,
        currentPageOffset,
        resetPagination
    }
});
