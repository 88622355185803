<template>
    <div class="toggle">
        <div
            class="toggle__option"
            :class="{ 'toggle__option--active': value == option }"
            v-for="option in props.options"
            :key="option"
            @click="value = option"
        >
            <span>{{ option }}</span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

const emit = defineEmits(['valueChanged']);

const props = defineProps({
    options: {
        default: ['Opt 1', 'Opt 2'],
    },
    initialValue: {
        default: 0,
    },
});

let value = ref(props.options[props.initialValue]);

watch(value, (newValue, oldValue) => {
    if (newValue == oldValue) return;
    emit('valueChanged', newValue);
});
</script>
