import {
    determineIfOptionSingularOrPlural,
    formatAttribute,
    formatStandardEquipment,
    formatTorque,
    formatEmissions,
} from '@/helpers/utils';

import {
    ATTRIBUTE_SCHEMA_MAPPING,
    ATTRIBUTE_OPERATOR_MAPPING,
    ATTRIBUTE_VALUE_SCHEMA_MAPPING,
    ATTRIBUTE_CODE_SCHEMA_MAPPING,
    BODY_TYPE_CODE_TO_TRANSLATION_MAPPING,
} from './adapterMapping';

import type { Car, CarOption, Filters, Filter, ApiFilter } from '@/types';
import { translate } from '@/helpers/utils';

export const filtersAdapter = (filters: Filters) => {
    const apiFilters: { filters: object[]; sorts: string[] } = {
        filters: [],
        sorts: [],
    };

    for (const filter of Object.values(filters)) {
        if (!filter.isDefault()) {
            const currentApiFilters: ApiFilter[] = filter.toApiFilter();
            if (currentApiFilters != undefined && currentApiFilters.length != 0)
                currentApiFilters.forEach((apiFilter: ApiFilter) => {
                    apiFilters.filters.push(apiFilter);
                });
            const currentApiSorts: string[] = filter.toApiSort();
            if (currentApiSorts != undefined && currentApiSorts.length != 0)
                currentApiSorts.forEach((apiSort: string) => {
                    apiFilters.sorts.push(apiSort);
                });
        }
    }

    return apiFilters;
};

export const vehicleDataAdapter = (
    vehicleId: number,
    vehicleData: any
): Car => {
    const options: CarOption[] = (vehicleData.options ?? []).map((o: any) => ({
        id: o.id,
        code: o.code,
        type: o.type,
        retail_price: o.retail_price,
        option_name: o.option_name,
        option_text: o.option_text
            .split('\n')
            .filter(
                (item: string) => !['and', 'or'].includes(item.toLowerCase())
            )
            .map((item: string) => item.replace(/\[.*\]\s+/, '')),
        changes_attributes: o.changes_attributes ?? [],
        option_build: o.option_build.map((b: any) => ({
            type: b.type,
            retail_price: b.retail_price,
            condition: b.condition,
            rule: b.rule,
        })),
    }));

    let engine_type = '';
    if (
        ['standard', 'de série', 'série', 'standaard'].includes(
            vehicleData.attributes['51801']?.value
        )
    ) {
        engine_type = 'hybrid';
    } else if (
        ['electric', 'Eléctrico', 'électrique', 'elektrisch'].includes(
            vehicleData.attributes['8708']?.value
        )
    ) {
        engine_type = 'electric';
    } else if (
        ['diesel', 'Diesel'].includes(vehicleData.attributes['8708']?.value)
    ) {
        engine_type = 'diesel';
    } else if (
        ['hydrogen', 'hydrogène', 'waterstof'].includes(
            vehicleData.attributes['8708']?.value
        )
    ) {
        engine_type = 'hydrogen';
    } else {
        engine_type = 'gasoline';
    }

    const fuel_tank_capacities = [];
    if (vehicleData.attributes['8903']) {
        fuel_tank_capacities.push(
            formatAttribute(vehicleData.attributes['8903'], 'L')
        );
    }
    if (vehicleData.attributes['8312']) {
        fuel_tank_capacities.push(
            formatAttribute(vehicleData.attributes['8312'], 'kWh')
        );
    }
    const fuel_tank_capacity = fuel_tank_capacities.length
        ? fuel_tank_capacities.join(' / ')
        : '-';

    let engine_power = '-';
    if (vehicleData.attributes['15303']) {
        const kwh = formatAttribute(vehicleData.attributes['15303'], 'kW');
        const hp = formatAttribute(
            Math.floor(vehicleData.attributes['15303'].value * 1.34102),
            translate('unit', 'cv')
        );
        engine_power = kwh + ' / ' + hp;
    }

    const charging_scenarios = [];
    if (vehicleData.attributes['53402'] && vehicleData.attributes['53403']) {
        charging_scenarios.push({
            mode: vehicleData.attributes['53402'].value,
            duration: formatAttribute(vehicleData.attributes['53403'], 'h'),
        });
    }
    if (
        vehicleData.attributes['53402_1'] &&
        vehicleData.attributes['53403_1']
    ) {
        charging_scenarios.push({
            mode: vehicleData.attributes['53402_1'].value,
            duration: formatAttribute(vehicleData.attributes['53403_1'], 'h'),
        });
    }
    if (
        vehicleData.attributes['53402_2'] &&
        vehicleData.attributes['53403_2']
    ) {
        charging_scenarios.push({
            mode: vehicleData.attributes['53402_2'].value,
            duration: formatAttribute(vehicleData.attributes['53403_2'], 'h'),
        });
    }

    let range = '-';
    if (vehicleData.attributes['8303']) {
        range = formatAttribute(vehicleData.attributes['8303'], 'km');
    } else if (vehicleData.attributes['42015']) {
        range = formatAttribute(vehicleData.attributes['42015'], 'km');
    } else if (vehicleData.attributes['62903']) {
        range = formatAttribute(vehicleData.attributes['62903'], 'km');
    }

    let strictType = '-';
    if (vehicleData.attributes['603']) {
        const code = vehicleData.attributes['603'].code;
        const name = BODY_TYPE_CODE_TO_TRANSLATION_MAPPING.get(code);
        strictType = formatAttribute(name);
    }

    let transmission = '-';
    if (vehicleData.attributes['420602']) {
        transmission =
            ATTRIBUTE_CODE_SCHEMA_MAPPING.get(
                vehicleData.attributes['420602'].code as string
            ) ?? '-';
    }

    const torque = formatTorque(
        engine_type,
        vehicleData.attributes['15307'],
        vehicleData.attributes['15322']
    );

    let apple_android_car = 'no_data';
    if (vehicleData.hasOwnProperty('standardEquipment')) {
        if (
            vehicleData.standardEquipment['59802'] ||
            vehicleData.standardEquipment['59803']
        ) {
            if (
                vehicleData.standardEquipment['59802']?.code == 'Y' &&
                vehicleData.standardEquipment['59803']?.code == 'Y'
            ) {
                apple_android_car = 'Apple CarPlay/Android Auto';
            } else if (vehicleData.standardEquipment['59802'].code == 'Y') {
                apple_android_car = 'Apple CarPlay';
            } else if (vehicleData.standardEquipment['59803'].code == 'Y') {
                apple_android_car = 'Android Auto';
            } else {
                apple_android_car = 'no_information';
            }
        }
    }

    function formatLeatherSeats(datapoint: any) {
        if (datapoint) {
            return (
                datapoint?.value +
                ' (' +
                datapoint?.options.count +
                ' ' +
                determineIfOptionSingularOrPlural(datapoint?.options.count) +
                ')'
            );
        } else {
            return 'no_data';
        }
    }

    function formatParkingAssistance(datapoint: any, type: any) {
        if (!datapoint) {
            return 'no_data';
        }
        const count = datapoint?.options.count;
        if (datapoint.code == 'S') {
            if (['A', 'D', 'E', 'F', 'H', 'I', 'J'].includes(type?.code)) {
                return 'standard';
            } else {
                if (count == 0) {
                    return 'no_information';
                } else {
                    return (
                        'optional (' +
                        (count ?? 0) +
                        ' ' +
                        determineIfOptionSingularOrPlural(count) +
                        ')'
                    );
                }
            }
        } else {
            if (count == 0) {
                return 'no_information';
            } else {
                return (
                    'optional (' +
                    (count ?? 0) +
                    ' ' +
                    determineIfOptionSingularOrPlural(count) +
                    ')'
                );
            }
        }
    }

    let standard_equipment: any = '';
    if (vehicleData.hasOwnProperty('standardEquipment')) {
        standard_equipment = {
            anti_collision_system: formatStandardEquipment(
                vehicleData.standardEquipment['44101']
            ),
            automatic_emergency_braking: formatStandardEquipment(
                vehicleData.standardEquipment['44106']
            ),
            lane_departure_warning: formatStandardEquipment(
                vehicleData.standardEquipment['46201']
            ),
            blind_spot_warning: formatStandardEquipment(
                vehicleData.standardEquipment['44701']
            ),

            parking_assistance: formatParkingAssistance(
                vehicleData.standardEquipment['49401'],
                vehicleData.standardEquipment['49403']
            ),
            rearview_camera: formatStandardEquipment(
                vehicleData.standardEquipment['21504']
            ),
            parking_sensors: formatStandardEquipment(
                vehicleData.standardEquipment['66037']
            ),
            key_less_entry: formatStandardEquipment(
                vehicleData.standardEquipment['37503']
            ),

            electric_trunk: formatStandardEquipment(
                vehicleData.standardEquipment['4101']
            ),
            trailer_hitch: formatStandardEquipment(
                vehicleData.standardEquipment['1601']
            ),
            foldable_rear_seats: formatStandardEquipment(
                vehicleData.standardEquipment['704']
            ),
            apple_android_car,

            seats_material: formatLeatherSeats(
                vehicleData.standardEquipment['17402']
            ),
            LED_lights: formatStandardEquipment(
                vehicleData.standardEquipment['50201']
            ),
            head_up_display: formatStandardEquipment(
                vehicleData.standardEquipment['9003']
            ),
            panoramic_roof: formatStandardEquipment(
                vehicleData.standardEquipment['67201']
            ),
        };
    }

    let energy_consumption_kWh_100km = '-';
    if (vehicleData.attributes['62803']) {
        // 62803 is in Wh/km so divide by 10
        energy_consumption_kWh_100km = formatAttribute(
            vehicleData.attributes['62803'].value / 10,
            'kWh/100km'
        );
    } else if (vehicleData.attributes['55404']) {
        energy_consumption_kWh_100km = formatAttribute(
            vehicleData.attributes['55404'],
            'kWh/100km'
        );
    }

    const car: Car = {
        id: vehicleId,
        urlKey: vehicleData.urlKey,
        img: vehicleData.image[0] || '/img/placeholder-car.svg',
        imgArray: vehicleData.image,
        price: vehicleData.attributes['902']?.value || '-',
        name: vehicleData.attributes['129']?.value || '-',
        model: vehicleData.attributes['129']?.code || '-',
        brand: vehicleData.attributes['128']?.value || '-',
        version: vehicleData.attributes['131']?.value || '-',
        availablePackages: options.filter((o) => o.type === 'package').length,
        options: options.filter((o) => o.type === 'option'),
        packages: options.filter((o) => o.type === 'package'),
        colors: options.filter(
            (o) =>
                o.type === 'color' &&
                Object.keys(o.changes_attributes).includes('31102') // Get only car body colors
        ),
        traction: formatAttribute(vehicleData.attributes['6502']),
        fuelType: engine_type,
        seatsNumber: formatAttribute(vehicleData.attributes['702']),
        doorNumber: formatAttribute(vehicleData.attributes['605']),
        type: formatAttribute(vehicleData.attributes['603']),
        strictType,
        transmission,
        power_output: formatAttribute(
            Math.floor(vehicleData.attributes['15303']?.value * 1.34102),
            translate('unit', 'cv')
        ),
        charging_time: formatAttribute(vehicleData.attributes['8304']),
        quick_charging_time: formatAttribute(vehicleData.attributes['8305']),
        range,
        engine_type,
        engine_power,
        top_speed: formatAttribute(vehicleData.attributes['13502'], 'km/h'),
        acceleration: formatAttribute(
            vehicleData.attributes['13503'],
            's (0-100 km/h)'
        ),
        torque: torque,
        fuel_consumption: formatAttribute(
            vehicleData.attributes['42008'],
            'km/l'
        ),
        fuel_consumption_l_100km: vehicleData.attributes['42008']
            ? (100 / vehicleData.attributes['42008'].value).toFixed(1) +
              ' l/100km'
            : '-',
        energy_consumption: formatAttribute(
            vehicleData.attributes['55422'],
            'km/kWh'
        ),
        energy_consumption_kWh_100km,
        tax_rate: formatAttribute(
            vehicleData.attributes['3516'],
            'I.S.V.',
            true
        ),
        car_width: formatAttribute(vehicleData.attributes['5803'], 'mm'),
        car_height: formatAttribute(vehicleData.attributes['5804'], 'mm'),
        car_length: formatAttribute(vehicleData.attributes['5802'], 'mm'),
        fuel_tank_capacity,
        battery_capacity: formatAttribute(
            vehicleData.attributes['8312']
                ? vehicleData.attributes['8312']
                : vehicleData.attributes['8333'],
            'kWh'
        ),
        car_weight: formatAttribute(vehicleData.attributes['24102'], 'kg'),
        boot_space: formatAttribute(vehicleData.attributes['6002'], 'L'),
        charging_scenarios,
        tax_isv: vehicleData.attributes['3516']
            ? vehicleData.attributes['3516'].value
            : '-',
        tax_iva: vehicleData.attributes['3517']
            ? vehicleData.attributes['3517'].value
            : '-',
        tax_ecovalor: vehicleData.attributes['3518']
            ? formatAttribute(vehicleData.attributes['3518'], undefined, true)
            : '-',
        tax_iuc: vehicleData.attributes['3520']
            ? vehicleData.attributes['3520'].value
            : '-',
        emissions: formatEmissions(
            [
                vehicleData.attributes['62203'],
                vehicleData.attributes['62203_1'],
                vehicleData.attributes['62203_2'],
                vehicleData.attributes['62203_3'],
            ],
            'gCO2/km'
        ),
        hybridization_upgrade: formatAttribute(vehicleData.attributes['48602']),
        standard_equipment: standard_equipment,
    };

    return car;
};
