declare global {
    interface Window {
        parentIFrame: any;
    }
}

interface PageInfo {
    iframeHeight: number;
    iframeWidth: number;
    offsetLeft: number;
    offsetTop: number;
    scrollLeft: number;
    scrollTop: number;
    documentHeight: number;
    documentWidth: number;
    windowHeight: number;
    windowWidth: number;
}

type PageInfoCallback = (data: PageInfo) => void;

export const inIframe = () => window.self !== window.top;

export const getPageInfo = (callback: PageInfoCallback) => {
    if (window.parentIFrame)
        window.parentIFrame.getPageInfo((data: PageInfo) => callback(data));
};
