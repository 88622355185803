import { getEnumKeyByEnumValue } from '@/helpers/enums';
import { createI18n, type I18n } from 'vue-i18n';
import { getTranslations } from './api';

export enum Locale {
    EN = 'en',
    PT = 'pt',
}
export const fallbackLocale = Locale.EN;

let i18n: I18n | null = null;

export default async function initLocalization(forcedLanguage: string) {
    const locale = forcedLanguage || getBrowserLanguage() || fallbackLocale;

    const messages = {
        [locale]: await getTranslations(locale),
    };
    i18n = createI18n({
        legacy: false,
        locale,
        fallbackLocale,
        messages,
    });

    return i18n;
}

export function getI18nInstance(): I18n | null {
    if (i18n) {
        return i18n;
    }
    return null;
}

function getBrowserLanguage() {
    let locale = null;
    if (window.navigator.languages) {
        locale = window.navigator.languages
            .map((l) => l.slice(0, 2))
            .find((l) => getEnumKeyByEnumValue(Locale, l));
    } else if (window.navigator.language) {
        locale = window.navigator.language.slice(0, 2);
    }
    return locale;
}
