import type { ApiFilter, Filter } from '@/types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { ATTRIBUTE_SCHEMA_MAPPING } from '@/libraries/api/adapterMapping';
import { useDataStore } from '@/stores/data';

export const fuelTypeFilter: Filter<
    Array<{
        title: string;
        value: string;
    }>
> = {
    type: 'fuel-type',
    valueFormat: 'multiple',
    value: [],
    defaultValue: [],
    noPreference: false,

    options: computed(() => []),

    isDefault: function (): Boolean {
        return this.value == this.defaultValue;
    },

    toFormatted: function (child: string): string {
        const { t } = useI18n();
        return t('fuel_type.' + child.replace('-', '_'));
    },
    toUrlQueryFormatted: function (): string {
        const result: string[] = [];
        this.value.forEach((v) => {
            result.push(v.value);
        });
        return result.join(',');
    },
    toFilterFromUrlQuery: function (value): any {
        return {
            title: this.toFormatted(value),
            value: value,
        };
    },
    hasValidValue: function (value): any {
        return useDataStore().fuelTypes.includes(value);
    },
    onValueChanged: function (): void {},
    toApiFilter: function (): ApiFilter[] {
        const VALUE_SCHEMA_MAPPING = new Map<string, string>([
            ['electric', 'E'],
            ['diesel', 'D'],
            ['gasoline', 'P'],
            ['hydrogen', 'H'],
        ]);

        const apiFilters: ApiFilter[] = [];

        for (const fuelType of this.value) {
            apiFilters.push({
                attribute:
                    ATTRIBUTE_SCHEMA_MAPPING.get('hybrid-engine') ??
                    'hybrid-engine',
                operator: '=',
                value: fuelType.value == 'hybrid' ? 'S' : '-',
            });

            if (fuelType.value != 'hybrid') {
                apiFilters.push({
                    attribute:
                        ATTRIBUTE_SCHEMA_MAPPING.get(this.type) ?? this.type,
                    operator: '=',
                    value:
                        VALUE_SCHEMA_MAPPING.get(fuelType.value) ??
                        fuelType.value,
                });
            }
        }

        return apiFilters;
    },
    toApiSort: function (): string[] {
        return [];
    },
};
